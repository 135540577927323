import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom'
import { Modal, Form, Card, Table, Button, Popconfirm, message, Input, Select, Switch, Row, Col, Alert } from 'antd';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from "react-redux";
import { GET_TRANSACAO_BYID } from "../../services/endpoints";
import api, { get, handleApiErrorResponse } from "../../services/api";
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {currencyFormatter} from '../../utils/functions';
import NavbarValidador from "../../components/NavbarValidador";
import NavbarBottomValidador from "../../components/NavbarBottomValidador";
import { infoStyle, infoStyleData } from "../../utils/functions";
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';

function Ticket(props) {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { tipo, operador, autorizacao } = useParams()

    const columns = useMemo(() => [
        {
            title: "Modalidade",
            dataIndex: "modalidade",
            key: "modalidade",
        },
        {
            title: "Produto",
            dataIndex: "nomeProduto",
            key: "nomeProduto",
        },
        // {
        //     title: "Data Evento",
        //     dataIndex: "dtmEvento",
        //     key: "dtmEvento",
        //     render: (text, item) => (
        //         <div>
        //            {moment(text).format('DD/MM/YYYY HH:MM')}
        //         </div>
        //     ),
        // },
        {
            title: "Valor",
            dataIndex: "valorAposta",
            key: "valorAposta",
            render: (text, item) => (
                <div>
                   {currencyFormatter(text)}
                </div>
            ),
        },
    ], []);

    useEffect(() => {
        //Buscar
        if (tipo != null && operador != null && autorizacao != null){
            setIsLoading(true);

            get(`${GET_TRANSACAO_BYID}/${tipo}/${operador}/${autorizacao}`, 
                (value) => {
                    setData(value.data.data);
                    setIsLoading(false);
                },
                (error) => {
                    handleApiErrorResponse(error, message, 'loading');
                    setIsLoading(false);
                }
            );
        }
    }, []);

    return (
      <div id="wrapper">
        <div style={{ padding:30 }}>            
            {data != null ? (
                <div>
                    <NavbarValidador 
                                colorDividerNavbar1={data.colorDividerNavbar1}
                                colorDividerNavbar2={data.colorDividerNavbar2}
                                colorDividerNavbar3={data.colorDividerNavbar3}
                                colorDividerNavbar4={data.colorDividerNavbar4}
                                logo={data.urlLogo}
                            />  
                    <Row 
                        style={{ 
                            paddingTop: '48px',
                            fontFamily: 'Georama, Arial, sans-serif',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                            color: (data.textColor) ? data.textColor : '#000',  
                            justifyContent: 'center',          
                        }} >
                        Consulta de Ticket
                    </Row>
                    {data.bitValido == null || data.bitValido == true ? (
                        data.situacao != null ? (
                            <>  
                                <Row style={{ marginTop: 20, marginBottom: 20 }}> 
                                    <Col span={24}>
                                        <Alert  message="Ticket Autêntico" type="success" showIcon />
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        paddingTop: '48px',
                                        paddingBottom: '16px',
                                        fontFamily: 'Georama, Arial, sans-serif',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        color: (data.textColor) ? data.textColor : '#000',
                                    }} >
                                    Dados do ticket
                                </Row> 
                                <Row style={infoStyle} > 
                                    Ponto de Venda: <p style={infoStyleData}> {data.nomePontoVenda} </p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Situação: <p style={infoStyleData}> {data.situacao} </p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Valor Total: <p style={infoStyleData}> {currencyFormatter(data.valorTotal)} </p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Código Autorização: <p style={infoStyleData}> {data.codigoAutorizacao} </p>
                                </Row>   
                                <Row
                                    style={{
                                        paddingTop: '48px',
                                        paddingBottom: '16px',
                                        fontFamily: 'Georama, Arial, sans-serif',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        color: (data.textColor) ? data.textColor : '#000',
                                    }} >
                                    Suas Apostas
                                </Row>
                                <Row style={{ paddingTop: 20, width:'100%' }}>
                                    <Table
                                        columns={columns.filter(el => el.type !== 'id' && el.hidden !== true)}    // Não renderiza o campo ID
                                        dataSource={data ? data.detalhes : []}
                                        pagination={false}
                                        size="small"
                                    /> 
                                </Row>
                                <div style={{ height: '200px'}}>                                    
                                </div>
                            </>
                        ):(
                            <Row style={{ marginTop: 20, marginBottom: 20 }}> 
                                <Col span={24}>
                                    <Alert  message="Ticket emitido em contingencia" type="success" description="Ticket válido. Em até 4h os dados do ticket estarão disponíveis para consulta." showIcon />
                                </Col>
                            </Row>
                        )                         
                    ) : (
                        <div>
                            <Row style={{ marginTop: 20, marginBottom: 20, paddingTop: 12 }}> 
                                <Col span={24}>
                                    <Alert  message="Ticket não Autêntico" 
                                    description={<>
                                        <>Entre em contato com a {data.nomeGestor?.toUpperCase()}.</>
                                        {/* <Row><small>Seu ticket não foi encontrado em nossos registros. Ele pode ter sido realizado em modo <b>offiline</b> e pode ser enviado pelo operador em até 24hrs após a realização da venda.</small></Row>
                                        <Row><small>Após este prazo, caso encontre problemas em validar seu ticket, favor entre em contato com nossa central de atendimento.</small></Row> */}
                                        <Row><MailOutlined style={{ paddingRight: 5}} /> 
                                            <a href={`mailto:${data.email ?? ""}`}>
                                                { data.email ?? ""} 
                                            </a>
                                        </Row>
                                        <Row><WhatsAppOutlined style={{paddingRight: 5 }} /> 
                                            <a href={`https://api.whatsapp.com/send?phone=${data.numeroCelular.replace(/\D+/g, '') ?? ""}`}>
                                                {data.numeroCelular ?? ""}
                                            </a>
                                        </Row>
                                    </>} type="error" showIcon />
                                </Col>
                            </Row>                           
                            
                        </div>
                    )}
                    <NavbarBottomValidador 
                            colorDividerNavbar1={data.colorDividerNavbar1}
                            colorDividerNavbar2={data.colorDividerNavbar2}
                            colorDividerNavbar3={data.colorDividerNavbar3}
                            colorDividerNavbar4={data.colorDividerNavbar4}
                            urlLogoEstado={data.urlLogoEstado}
                            nomeGestor={data.nomeGestor}
                            redes={data.redes}
                        />
                </div>
                ) : (
                    <div>
                    </div>
                )}
        </div>
      </div>
    );
}

export default Ticket;
