
import React from "react";
import { Row, Col } from 'antd';

export default function NavbarLine(props) {
    return (
        <Row
            style={{
                width: '100%',
                height: '3.577px',
                top: 0,
                left: 0,
                right: 0,
                marginTop: 0,
            }}
        >
            <Col span={6} style={{ backgroundColor: props.colorDividerNavbar1 ? props.colorDividerNavbar1 : '#fff', width: '100%' }}></Col>
            <Col span={6} style={{ backgroundColor: props.colorDividerNavbar2 ? props.colorDividerNavbar2 : '#fff', width: '100%' }}></Col>
            <Col span={6} style={{ backgroundColor: props.colorDividerNavbar3 ? props.colorDividerNavbar3 : '#fff', width: '100%' }}></Col>
            <Col span={6} style={{ backgroundColor: props.colorDividerNavbar4 ? props.colorDividerNavbar4 : '#fff', width: '100%' }}></Col>
        </Row>
    );
}