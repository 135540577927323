
import React from "react";
import NavbarLine from "../NavbarLine";

export default function NavbarValidador(props) {
    return (
        <nav className="navbar navbar-fixed-top">    
            <NavbarLine 
                colorDividerNavbar1={props.colorDividerNavbar1}
                colorDividerNavbar2={props.colorDividerNavbar2}
                colorDividerNavbar3={props.colorDividerNavbar3}
                colorDividerNavbar4={props.colorDividerNavbar4}
            />                        
            
            <div /*className="container-fluid"*/>      
                <div className="navbar-brand">
                    <a href="#">
                        <img
                            src={props.logo ?? ""}
                            alt="Logo"
                            className="img-responsive logo"
                            style={{ width: 114, marginLeft: 5 }}
                        />
                    </a>
                </div>
                <div className="navbar-right">
                </div>
            </div>
        </nav>
    );
}