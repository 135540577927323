import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom'
import { Tag, Card, message, Divider, Row, Col, Alert } from 'antd';
import { GET_VALIDA_OPERADORLOT } from "../../services/endpoints";
import api, { get, handleApiErrorResponse } from "../../services/api";
import Logo from '../../assets/images/logo.png';
import {cepFormatter} from '../../utils/functions';
import moment from 'moment';
import { cnpj } from 'cpf-cnpj-validator';
import NavbarValidador from "../../components/NavbarValidador";
import NavbarBottomValidador from "../../components/NavbarBottomValidador";
import { infoStyle, infoStyleData } from "../../utils/functions";
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';

function Validador(props) {
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const { domain, operador_id, hash_val } = useParams()

    useEffect(() => {
        //Buscar
        if (operador_id != null){
            setIsLoading(true);
            get(`${GET_VALIDA_OPERADORLOT}/${operador_id}/${domain}/${hash_val}`, 
                (value) => {
                    setData(value.data.data);
                    setIsLoading(false);
                },
                (error) => {
                    handleApiErrorResponse(error, message, 'loading');
                    setData(null);
                    setIsLoading(false);
                }
            );
        }
    }, []);

    return (
        <div id="wrapper1" style={{ alignContent:'center'}}>
          <div style={{ padding:30 }}>
            {data !== undefined && data !== null  ? (
                <div>
                    <NavbarValidador 
                            colorDividerNavbar1={data.colorDividerNavbar1}
                            colorDividerNavbar2={data.colorDividerNavbar2}
                            colorDividerNavbar3={data.colorDividerNavbar3}
                            colorDividerNavbar4={data.colorDividerNavbar4}
                            logo={data.urlLogo}
                        />   
                    <Row 
                        style={{ 
                            paddingTop: '48px',
                            fontFamily: 'Georama, Arial, sans-serif',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                            color: (data.textColor) ? data.textColor : '#000',  
                            justifyContent: 'center',          
                        }} >
                        Consulta de Licença
                    </Row>                    
                    {data.bitAtivo ? (                  
                        <div>                            
                            <Row style={{ marginTop: 20, marginBottom: 20 }}> 
                                <Col span={24}>
                                    <Alert  message="Operador Habilitado" description="Este operador foi certificado e homologado para operar em sua região" type="success" showIcon />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 0, marginBottom: 20 }}>                           
                                <Col span={12}> 
                                    <small> 
                                        Esta licença foi validada em {moment(data.dtmValidacao).local().format('DD/MM/YYYY HH:mm:ss')}
                                    </small>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    paddingTop: '48px',
                                    paddingBottom: '16px',
                                    fontFamily: 'Georama, Arial, sans-serif',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: 'normal',
                                    color: (data.textColor) ? data.textColor : '#000',
                                }} >
                                Informações do Operador
                            </Row> 
                            <Row style={infoStyle} > 
                                Operador Lotérico: <p style={infoStyleData}> {data.nomeFantasia} </p>
                            </Row>  
                            <Row style={infoStyle} > 
                                CNPJ: <p style={infoStyleData}> {(data.cnpj !== "") ? `${cnpj.format(data.cnpj)}`  : ""} </p>
                            </Row> 
                            <Row style={infoStyle} > 
                                Razão Social: <p style={infoStyleData}> {data.nome} </p>
                            </Row> 
                            <Row style={infoStyle} > 
                                Website Registrado: <p style={infoStyleData}> <a href={`//${data.urlDominio}`}>
                                        {data.urlDominio}
                                    </a> </p>
                            </Row>  
                            <Row style={infoStyle} > 
                                E-mail: <p style={infoStyleData}> {data.email} </p>
                            </Row>
                            <Row style={infoStyle} > 
                                Endereço: <p style={infoStyleData}> {((data.logradouro ? data.logradouro  : "" ) !== "") ? ` ${data.logradouro}, ` : "" }
                                    {((data.complemento ? data.complemento : "") !== "") ? `${data.complemento}, ` : "" }
                                    {((data.bairro ? data.bairro : "") !== "") ? `${data.bairro}, ` : "" }
                                    {`${(data.sigla_uf ? data.sigla_uf : "")}`}
                                    {cepFormatter(data.cep)} </p>
                            </Row>
                            <Divider />
                            <Row style={{ marginTop: 0, marginBottom: 180, paddingBottom: 20 }}> 
                                <Col span={24}>
                                    <div  style={{ whiteSpace: 'pre-wrap' }} 
                                        dangerouslySetInnerHTML={{ __html: data.disclaimer.replaceAll('@razao_social', data.nome)
                                                        .replaceAll('@nome_fantasia', data.nomeFantasia)
                                                        .replaceAll('@cnpj', (data.cnpj !== "") ? ` ${cnpj.format(data.cnpj)}`  : "") }}  >   
                                    </div> 
                                </Col>
                            </Row>
                            <div style={{ height: '200px'}}>                                    
                            </div>
                        </div>
                        ) : (
                            <div>
                                <Row style={{ marginTop: 20, marginBottom: 20 }}> 
                                    <Col span={24}>
                                        <Alert  message="Operador não Habilitado" description={
                                            <>
                                                <>Não foi possível validar a licença para o website {
                                                    <a href={`${ domain ?? ""}`}>
                                                        { domain ?? ""} 
                                                    </a>
                                                }</>
                                                <Row><MailOutlined style={{ paddingRight: 5}} /> 
                                                    <a href={`mailto:${data.email ?? ""}`}>
                                                        { data.email ?? ""} 
                                                    </a>
                                                </Row>
                                                <Row><WhatsAppOutlined style={{paddingRight: 5 }} /> 
                                                    <a href={`https://api.whatsapp.com/send?phone=${data.numeroCelular.replace(/\D+/g, '') ?? ""}`}>
                                                        {data.numeroCelular ?? ""}
                                                    </a>
                                                </Row>
                                            </>
                                        }   type="error" showIcon />
                                    </Col>                                  
                                </Row>
                            </div>
                        )}
                        <NavbarBottomValidador 
                                    colorDividerNavbar1={data.colorDividerNavbar1}
                                    colorDividerNavbar2={data.colorDividerNavbar2}
                                    colorDividerNavbar3={data.colorDividerNavbar3}
                                    colorDividerNavbar4={data.colorDividerNavbar4}
                                    urlLogoEstado={data.urlLogoEstado}
                                    nomeGestor={data.nomeGestor}
                                    redes={data.redes}
                                />
                    </div>
                ) : (
                    <div>
                    </div>
                )}
          </div>
        </div>
      );
}

export default Validador;