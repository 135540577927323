import { createSlice } from '@reduxjs/toolkit'
import { defineState } from 'redux-localstore';

const defaultState = {
    themeColor: "theme-blue", 
    sideMenuTab: [true, false],
    menuOpen: true,
}

const initialState = defineState(defaultState)('navigation');

const navigationSlicer = createSlice({
     name: 'navigation',
     initialState: initialState,
     reducers: {
        changeThemeColor: (state, action) => {
            state.themeColor = `theme-${action.payload}`;
        },
        toggleSideMenu: (state, action) => {
            state.menuOpen = !state.menuOpen;
        },
        changeTabMenu: (state, action) => {
            var menu = [false, false];
            menu[action.payload] = true;
            state.sideMenuTab = menu;
        },
     }
 });

 export const { changeThemeColor, toggleSideMenu, changeTabMenu } = navigationSlicer.actions;

 export const selectThemeColor = (state) => {
    return state.navigation.themeColor;
 }

export const selectSideMenuTab = (state) => {
     return state.navigation.sideMenuTab;
 }

export const selectMenuState = (state) => {
    return state.navigation.menuOpen;
}

export default navigationSlicer.reducer;