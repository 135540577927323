import React from "react";
import { Divider } from 'antd';
import NavbarLine from "../NavbarLine";
import { TwitterOutlined, InstagramOutlined, YoutubeOutlined, WhatsAppOutlined } from '@ant-design/icons';

export default function NavbarBottomValidador(props) {
    return (
        <nav className="navbar navbar-fixed-bottom" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, paddingTop: 0, backgroundColor:'white'}}>    
            <NavbarLine 
                colorDividerNavbar1={props.colorDividerNavbar1}
                colorDividerNavbar2={props.colorDividerNavbar2}
                colorDividerNavbar3={props.colorDividerNavbar3}
                colorDividerNavbar4={props.colorDividerNavbar4}
            />
            <div style={{ left: 16, paddingTop: 12, marginLeft: 5 }}>
                <img
                    src={props.urlLogoEstado ?? ""}
                    alt="Logo do Estado"
                    style={{ width: 200 }}
                />
            </div>
            <div style={{ right: 16, paddingTop: 12, marginRight: 5 }}>
                {props.redes?.urlFacebook ? (
                    <i  style={{ fontSize: 18, marginRight: 11, cursor: 'pointer' }}
                        onClick={() => {window.open(`https://${props.redes?.urlFacebook.replace("https://", "")}`, "_blank")}}
                        class="fa fa-facebook" aria-hidden="true" /> ):(<></>)
                }
                {props.redes?.urlTwitter ? (
                    <TwitterOutlined 
                    style={{ fontSize: 18, marginRight: 11 }}
                    onClick={() => {window.open(`https://${props.redes?.urlTwitter.replace("https://", "")}`, "_blank")}}
                    /> ):(<></>)
                }
                {props.redes?.urlInstagram ? (
                    <InstagramOutlined 
                    style={{ fontSize: 18, marginRight: 11 }}
                    onClick={() => {window.open(`https://${props.redes?.urlInstagram.replace("https://", "")}`, "_blank")}}
                    /> ):(<></>)
                }
                {props.redes?.urlYoutube ? (
                    <YoutubeOutlined 
                    style={{ fontSize: 18, marginRight: 11 }}
                    onClick={() => {window.open(`https://${props.redes?.urlYoutube.replace("https://", "")}`, "_blank")}}
                    /> ):(<></>)
                }
                {props.redes?.urlTelegram ? (
                    <i  style={{ fontSize: 18, marginRight: 11, cursor: 'pointer' }}
                        onClick={() => {window.open(`https://${props.redes?.urlTelegram.replace("https://", "")}`, "_blank")}}
                        class="fa fa-telegram" aria-hidden="true" /> ):(<></>)
                }
                {props.redes?.urlTiktok ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 21"
                        style={{ fontSize: 18, marginRight: 11, cursor: 'pointer' }}
                        onClick={() => {window.open(`https://${props.redes?.urlTiktok.replace("https://", "")}`, "_blank")}}>
                        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/>
                    </svg> ):(<></>)
                }
            </div>
            <Divider style={{ marginTop: 0 }} />
            <div style={{ width: '100%', textAlign: 'center' }}>
                {`Copyright © ${new Date().getFullYear()} ${props.nomeGestor ?? ""} - Todos os direitos reservados.`}
            </div>
        </nav>
    );
}