import axios from "axios";
import { getToken, getOperador } from "./auth";

const api = axios.create();

api.interceptors.request.use(async config => {
    if (config.url.indexOf("Usuario/AutenticarUsuario") === -1 
        && config.url.indexOf("viacep") === -1
        && config.url.indexOf("Transacao") === -1
        && config.url.indexOf("Terminal/Consultar") === -1
        && config.url.indexOf("ValidaOperadorLoterico") === -1){

        const token = getToken();
        config.headers.Authorization = `Bearer ${token}`;

        config.headers.OpID = getOperador();
    }
    return config;
});

export default api;

export async function get(path, success, error) {

    await api
        .get(path)
        .then((value) => {
            success(value);
        })
        .catch((err) => {
            error(err);
        });
}

export async function Delete(path, payload, success, error) {
    await api
        .delete(path, payload)
        .then((value) => {
            success(value);
        })
        .catch((err) => {
            error(err);
        });
}

export async function post(path, payload, success, error) {
    await api
        .post(path, payload)
        .then((value) => {
            success(value);
        })
        .catch((err) => {
            error(err);
        });
}


export function handleApiErrorResponse(error, message, messageKey) {
    var msg = error.toString();
    if (error && error.response) {
        msg = error.response.data.message
            ? error.response.data.message
            : error.response.data.title;
    } else if (error && error.request) {
        msg = 'Falha ao efetuar comunicação. Verifique a conexão com a internet.';
    } else {
        msg = 'Falha ao efetuar comunicação. Tente novamente.';
    }

    if (message) {
        message.error({ content: msg, key: messageKey });
    }
}

export function getErrorMessage(error) {
    let msg = '';
    if (error.response) 
    {
        msg = error.response.data?.message
    } 
    else if (error.request) 
    {
        // The request was made but no response was received
        msg = 'Falha ao efetuar comunicação. Verifique sua conexão.';
    } 
    else 
    {
        // Something happened in setting up the request that triggered an Error
        msg = 'Falha ao efetuar comunicação.';
    }
    return msg;
}