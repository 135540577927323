import * as Cookies from 'js-cookie';
import { Buffer } from 'buffer';

const SESSION_KEY = "session"
const OPERADOR_KEY = "op_id"
const EXPIRATION_TIME = 12 * 60 * 60 * 1000;

export const setSessionCookie = (session) => {
    Cookies.remove(SESSION_KEY);

    if (session.manterLogado) {
        Cookies.set(SESSION_KEY, session, { expires: 7, path: '/', domain: process.env.REACT_APP_URL});
    } else {
        Cookies.set(SESSION_KEY, session);
    }
};

export const getSessionCookie = () => {
    const sessionCookie = Cookies.get(SESSION_KEY, {domain: process.env.REACT_APP_URL});
    return (sessionCookie ? JSON.parse(sessionCookie) : null);
}

export const clearSession = () => {
    Cookies.remove(SESSION_KEY);
}


export const setLocalStorage = (session) => {
    localStorage.removeItem(SESSION_KEY);
    localStorage.setItem(SESSION_KEY, 
        Buffer.from(
            JSON.stringify({
                value: session,
                expires: (new Date()).setTime((new Date()).getTime() + EXPIRATION_TIME)
            })
        ).toString('base64')
    );     
};

export const getLocalStorage = () => {
    const sessionStorageItem = localStorage.getItem(SESSION_KEY);
    const session = (sessionStorageItem ? JSON.parse(Buffer.from(sessionStorageItem, 'base64')) : null);        
    if(!session || Date.now() > session.expires) clearLocalStorage();
    return ((session && Date.now() <= session.expires) ? session.value : null);
}

export const getLocalStorageOperador = () => {
    const operadorStorageItem = localStorage.getItem(OPERADOR_KEY);
    return (operadorStorageItem) ? operadorStorageItem : "";
}

export const setLocalStorageOperador  = (value) => {
    localStorage.removeItem(OPERADOR_KEY);
    localStorage.setItem(OPERADOR_KEY, value);
};

export const clearLocalStorage = () => {
    localStorage.removeItem(SESSION_KEY);   
    localStorage.removeItem(OPERADOR_KEY);   
}
