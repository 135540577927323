import axios from "axios";
import moment from 'moment';

const locale = "pt-br";
export const CEP_REGEX_PATTERN = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
export const TEL_REGEX_PATTERN = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/;
export const AVAILABLE_REGIONS = ['Brazil'];
export const infoStyle = {
  fontFamily: 'Georama, Arial, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',                                
  fontWeight: '700',
  lineHeight: 'normal',
  color: '#273B32',
};
export  const infoStyleData = {fontWeight: '500', paddingLeft: '5px'};


export const currencyInputFormatter = (value) => {
    value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

export const currencyInputParser = (value) => {
    value.replace('.', '').replace(',', '');
}

export const percentFormatter = value => {
  return new Intl.NumberFormat(locale).format(value);
};

export const currencyFormatter = value => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "BRL"
    }).format(value);
  };

export const cepFormatter = value => {
  return (value ? value : "").replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/,"$1.$2-$3");
};

export const telFormatter = value => {
  return (value ? value : "").replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
};

export const currencyParser = val => {
    try {
      // for when the input gets clears
      if (typeof val === "string" && !val.length) {
        val = "0.0";
      }
  
      // detecting and parsing between comma and dot
      var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
      //  => 1232.21 €
  
      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, "");
      //  => 1232.21
  
      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;
  
      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }
  
      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
  };

  export const nullableDateFormatter = value => {
    return moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : "";
  };

  export const getTipo = value => {
    switch(value){
      case 1:
        return "Pagamento em Espécie";
      case 2:
        return "Deposito em Conta";
      default:
          return "";
    }
  };

  export const checkValor = (number) => {
    if (number > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Valor deve ser maior que zero!'));
  };

  export const getAddressInfo = async (street, city, state, country) => {
      const url = `https://nominatim.openstreetmap.org/search?street=${street.split(' ').join('+')}&city=${city.split(' ').join('+')}&state=${state.split(' ').join('+')}&country=${country.split(' ').join('+')}&format=json`;
      axios.get(url)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
  }

  export const dateDiffinDays = (date1, date2) => {
    return new Date(new Date(date2) - new Date(date1)).getDate() +1
  }

  export const translateTimezoneNames = (timezone) => {
    return timezone.replace("Brazil/", "").replace("West", "Amazonas").replace("East", "Brasília").replace("DeNoronha", "Fernando de Noronha");
  }

  export const translateRegionNames = (region) => {
    var r = region.replace("Brazil", "Brasil");
    return r;
  }