import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { store } from './redux/config';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/assets/scss/main.scss";
import "./assets/assets/scss/color_skins.scss";
import "../node_modules/font-awesome/scss/font-awesome.scss";   
import { Provider as LoginProvider } from './context/LoginContext';
//import reportWebVitals from './reportWebVitals';


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <LoginProvider>
                <App />
            </LoginProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
