import { applyMiddleware, createStore, combineReducers } from 'redux';
import storeSynchronize from 'redux-localstore';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';
import navigationSlice from './slicers/navigation';

const rootReducer = combineReducers({
  navigation: navigationSlice,
});

//const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);

const store = createStore(rootReducer, middleware);
storeSynchronize(store,{
    storage: 'localStorage'
});
export { store };