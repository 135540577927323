import React, { useState, createContext } from 'react';

export const MenuContext = createContext();

export const MenuProvider = props => {
    const [activeItem, setActiveItem] = useState('home');

    return (
        <MenuContext.Provider value={{activeItem, setActiveItem}}>
            {props.children}
        </MenuContext.Provider>
    );
};