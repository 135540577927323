// import { getSessionCookie } from '../session';
import { getLocalStorage, getLocalStorageOperador } from '../session';

export const getToken = () => {
  // return getSessionCookie().token;
  return getLocalStorage()?.token;
}

export const getOperador = () =>{
  return getLocalStorageOperador();
}


