const baseURL = process.env.REACT_APP_API_URL;
const WEB_PREFIX = baseURL + '/';

export const LOGIN                          = WEB_PREFIX + 'Usuario/AutenticarUsuario';
export const SELECIONA_OPERADOR             = WEB_PREFIX + 'Usuario/SelecionaOperador'
export const GERAR_2FA                      = WEB_PREFIX + 'Usuario/Gerar2FA'
export const VERIFICA_OPT                   = WEB_PREFIX + 'Usuario/VerificaOPT'
export const RESET2FA                       = WEB_PREFIX + 'Usuario/Reset2FA'
export const GET_TIPOS_AGENCIA              = WEB_PREFIX + 'TipoAgencia';
export const DELETE_TIPO_AGENCIA            = WEB_PREFIX + 'TipoAgencia';
export const SAVE_TIPO_AGENCIA              = WEB_PREFIX + 'TipoAgencia';
export const GET_TIPOS_ATIVIDADE            = WEB_PREFIX + 'TipoAtividade';
export const DELETE_TIPO_ATIVIDADE          = WEB_PREFIX + 'TipoAtividade';
export const SAVE_TIPO_ATIVIDADE            = WEB_PREFIX + 'TipoAtividade';
export const GET_TIPOS_REPASSE              = WEB_PREFIX + 'TipoRepasse';
export const DELETE_TIPO_REPASSE            = WEB_PREFIX + 'TipoRepasse';
export const SAVE_TIPO_REPASSE              = WEB_PREFIX + 'TipoRepasse';
export const GET_FAIXA_ETARIA               = WEB_PREFIX + 'FaixaEtaria';
export const DELETE_FAIXA_ETARIA            = WEB_PREFIX + 'FaixaEtaria';
export const SAVE_FAIXA_ETARIA              = WEB_PREFIX + 'FaixaEtaria';
export const GET_MODALIDADES                = WEB_PREFIX + 'Modalidade';
export const DELETE_MODALIDADE              = WEB_PREFIX + 'Modalidade';
export const SAVE_MODALIDADE                = WEB_PREFIX + 'Modalidade';
export const GET_CONFIGURACAO_REPASSE       = WEB_PREFIX + 'RepasseConfiguracao';
export const DELETE_CONFIGURACAO_REPASSE    = WEB_PREFIX + 'RepasseConfiguracao';
export const SAVE_CONFIGURACAO_REPASSE      = WEB_PREFIX + 'RepasseConfiguracao';
export const GET_DESTINO_REPASSE            = WEB_PREFIX + 'RepasseDestino';
export const DELETE_DESTINO_REPASSE         = WEB_PREFIX + 'RepasseDestino';
export const SAVE_DESTINO_REPASSE           = WEB_PREFIX + 'RepasseDestino';
export const GET_OPERADORESLOT              = WEB_PREFIX + 'OperadorLoterico';
export const DELETE_OPERADORLOT             = WEB_PREFIX + 'OperadorLoterico';
export const SAVE_OPERADORLOT               = WEB_PREFIX + 'OperadorLoterico';
export const GET_ALL_OPERADORESLOT          = WEB_PREFIX + 'OperadorLoterico/BuscarTodos';
export const SAVE_PENALIDADE_OPERADOR       = WEB_PREFIX + 'OperadorLoterico/Penalidade';
export const SAVE_CONTATOS_OPERADOR         = WEB_PREFIX + 'OperadorLoterico/Contatos';
export const SAVE_ENDERECO_OPERADOR         = WEB_PREFIX + "OperadorLotericoVisaoOperador/AtualizarEndereco";
export const GET_CONTIGENCIA_OPERADORESLOT  = WEB_PREFIX + 'OperadorContigencia';
export const GET_OPERADORESLOTMOD           = WEB_PREFIX + 'OperadorLotericoModalidade';
export const DELETE_OPERADORLOTMOD          = WEB_PREFIX + 'OperadorLotericoModalidade';
export const SAVE_OPERADORLOTMOD            = WEB_PREFIX + 'OperadorLotericoModalidade';
export const GET_PRODUTO_OPERADORESLOT      = WEB_PREFIX + 'ProdutoOperadorLoterico';
export const DELETE_PRODUTO_OPERADORESLOT   = WEB_PREFIX + 'ProdutoOperadorLoterico';
export const SAVE_PRODUTO_OPERADORESLOT     = WEB_PREFIX + 'ProdutoOperadorLoterico';
export const GET_PRODUTO_OPERALOT_PRODUTOS  = WEB_PREFIX + 'ProdutoOperadorLoterico/Produtos';
export const GET_PRODUTO_ByFILTER           = WEB_PREFIX + 'Produto/BuscarByFilter';
export const GET_PRODUTO                    = WEB_PREFIX + 'Produto/BuscarTodos';
export const DELETE_PRODUTO                 = WEB_PREFIX + 'Produto';
export const SAVE_PRODUTO                   = WEB_PREFIX + 'Produto';
export const GET_CANAL_VENDA                = WEB_PREFIX + 'CanalVenda';
export const DELETE_CANAL_VENDA             = WEB_PREFIX + 'CanalVenda';
export const SAVE_CANAL_VENDA               = WEB_PREFIX + 'CanalVenda';
export const GET_PROFISSAO                  = WEB_PREFIX + 'Profissao';
export const DELETE_PROFISSAO               = WEB_PREFIX + 'Profissao';
export const SAVE_PROFISSAO                 = WEB_PREFIX + 'Profissao';
export const GET_INSTITUICAO                = WEB_PREFIX + 'Instituicao';
export const DELETE_INSTITUICAO             = WEB_PREFIX + 'Instituicao';
export const SAVE_INSTITUICAO               = WEB_PREFIX + 'Instituicao';
export const GET_CONTATOS                   = WEB_PREFIX + 'Contato/BuscarTodos';
export const GET_CONFIG                     = WEB_PREFIX + 'Configuracao';
export const SAVE_CONFIG                    = WEB_PREFIX + 'Configuracao';
export const GET_MAP_KEY                    = WEB_PREFIX + 'Configuracao/BuscarMapKey';
export const GET_APOSTADORES                = WEB_PREFIX + 'Apostador';
export const DELETE_APOSTADOR               = WEB_PREFIX + 'Apostador';
export const SAVE_APOSTADOR                 = WEB_PREFIX + 'Apostador';
export const GET_APOSTADOR_RESTRICAO        = WEB_PREFIX + 'Apostador/Restricao';
export const SAVE_APOSTADOR_RESTRICAO       = WEB_PREFIX + 'Apostador/Restricao';
export const DELETE_APOSTADOR_RESTRICAO     = WEB_PREFIX + 'Apostador/Restricao';
export const BUSCAR_APOSTADORES_ByFILTER    = WEB_PREFIX + "Apostador/BuscarByFilter";

export const REL_APOSTADOR                  = WEB_PREFIX + "RelApostador";
export const REL_AUDITORIA                  = WEB_PREFIX + 'RelAuditoria';
export const REL_NAO_CONFORMIDADES          = WEB_PREFIX + 'RelNaoConformidades';
export const REL_REDEDISTRIBUICAO           = WEB_PREFIX + 'RelRedeDistribuicao';
export const REL_TRANSACOES                 = WEB_PREFIX + 'RelTransacoes';
export const REL_SORTEIO                    = WEB_PREFIX + 'RelSorteio';
export const REL_REPASSE                    = WEB_PREFIX + 'RelRepasse';
export const REL_PREMIOS                    = WEB_PREFIX + 'RelPremioRetencao';
export const REL_TERMINAIS                  = WEB_PREFIX + 'RelTerminais';
export const REL_IMPOSTO_RETIDO             = WEB_PREFIX + 'RelImpostoRetido';
export const REL_OPERADOR_LOTERICO          = WEB_PREFIX + 'RelOperadorLoterico';

export const CONSULTA_WALLET                = WEB_PREFIX + 'HisWalletOperadorloterico';
export const CONSULTA_HIS_WALLET            = WEB_PREFIX + 'HisWalletOperadorloterico/BuscarByFilter';

export const CONSULTA_CLIENTES              = WEB_PREFIX + 'HisClientes';
export const CONSULTA_HIS_CLIENTES          = WEB_PREFIX + 'HisClientes/BuscarByFilter';

export const CONSULTA_ESTOQUE_INSTANT       = WEB_PREFIX + 'HisEstoqueInstantanea';
export const CONSULTA_HIS_ESTOQUE_INSTANT   = WEB_PREFIX + 'HisEstoqueInstantanea/BuscarByFilter';

export const GET_USUARIOS                   = WEB_PREFIX + 'Usuario';
export const DELETE_USUARIO                 = WEB_PREFIX + 'Usuario';
export const SAVE_USUARIO                   = WEB_PREFIX + 'Usuario';
export const GET_USUARIOS_OPERADORESLOT     = WEB_PREFIX + 'UsuarioOperadorLoterico';
export const GET_USUARIO_OPERADORESLOT_ById = WEB_PREFIX + 'UsuarioOperadorLoterico';
export const DELETE_USUARIO_OPERADORESLOT   = WEB_PREFIX + 'UsuarioOperadorLoterico';
export const SAVE_USUARIO_OPERADORESLOT     = WEB_PREFIX + 'UsuarioOperadorLoterico';
export const REDEFINIR_SENHA                = WEB_PREFIX + 'Usuario/RedefinirSenha';
export const GET_PERFIS                     = WEB_PREFIX + 'Perfil';
export const DELETE_PERFIL                  = WEB_PREFIX + 'Perfil';
export const SAVE_PERFIL                    = WEB_PREFIX + 'Perfil';
export const GET_ACESSO_PERFIL              = WEB_PREFIX + 'Perfil/Acesso';
export const SAVE_PERMISSOES                = WEB_PREFIX + 'Perfil/InserirPermissoes';

export const GET_PONTOS                     = WEB_PREFIX + 'PontoVenda';
export const DELETE_PONTOS                  = WEB_PREFIX + 'PontoVenda';
export const SAVE_PONTOS                    = WEB_PREFIX + 'PontoVenda';
export const GET_CIDADE_BY_IBGECODE         = WEB_PREFIX + "PontoVenda/BuscarCidadeByCodIbge";
export const GET_ESTADOS                    = WEB_PREFIX + "PontoVenda/BuscarEstados";
export const GET_STATUS_PONTO               = WEB_PREFIX + "PontoVenda/BuscarStatusPonto";
export const GET_PONTOS_VENDA               = WEB_PREFIX + "PontoVenda/BuscarTodos";
export const BUSCAR_PONTOS_ByFILTER         = WEB_PREFIX + "PontoVenda/BuscarByFilter";
export const SAVE_ENDERECO_PONTO            = WEB_PREFIX + "PontoVenda/AtualizarEndereco";

export const GET_TRANSACAO_BYID             = WEB_PREFIX + 'Transacao';
export const GET_TIPOS_NAO_CONFORMIDADES    = WEB_PREFIX + 'TiposNaoConformidades'
export const GET_CONSULTA_TERMINAL_BYID     = WEB_PREFIX + 'Terminal/Consultar';
export const GET_VALIDA_OPERADORLOT         = WEB_PREFIX + 'ValidaOperadorLoterico';

export const GET_DISCLAIMER_VALIDADOS       = WEB_PREFIX + 'BuscarDisclaimerValidador';

