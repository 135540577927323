import createDataContext from './createDataContext';
// import { clearSession, getSessionCookie, setSessionCookie } from '../session';
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '../session';

const ACTION_LOGIN  = 'login';
const ACTION_LOGOUT = 'logout';

const loginReducer = (state, action) => {
    switch(action.type) {
        case ACTION_LOGIN:
            // setSessionCookie(action.payload);
            setLocalStorage(action.payload);
            // return getSessionCookie();
            return getLocalStorage();

        case ACTION_LOGOUT:
            // clearSession();
            clearLocalStorage();
            // return getSessionCookie();
            return getLocalStorage();

        default:
            return state;
    }
};

const login = dispatch => {
    return (userData) => {
        dispatch({ type: ACTION_LOGIN, payload: userData })
    }
};

const logout = dispatch => {
    return () => {
        dispatch({ type: ACTION_LOGOUT });
    }
};

export const { Context, Provider } = createDataContext(
    loginReducer,           // reducer
    { login, logout },      // actions
    getLocalStorage()      // initialState
    // getSessionCookie()      // initialState
);