import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom'
import { Modal, Form, Card, Table, Button, Popconfirm, message, Input, Select, Switch, Row, Col, Layout, Alert } from 'antd';
import { GET_CONSULTA_TERMINAL_BYID } from "../../services/endpoints";
import api, { get, handleApiErrorResponse } from "../../services/api";
import {cepFormatter} from '../../utils/functions';
import NavbarValidador from "../../components/NavbarValidador";
import NavbarBottomValidador from "../../components/NavbarBottomValidador";
import { infoStyle, infoStyleData } from "../../utils/functions";
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';

function Terminal(props) {
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const { terminal_id } = useParams();
    const { Footer } = Layout;
    
    useEffect(() => {
        //Buscar
        if (terminal_id != null){
            setIsLoading(true);
            get(`${GET_CONSULTA_TERMINAL_BYID}/${terminal_id}`, 
                (value) => {
                    setData(value.data.data);
                    setIsLoading(false);
                },
                (error) => {
                    handleApiErrorResponse(error, message, 'loading');
                    setData(null);
                    setIsLoading(false);
                }
            );
        }
    }, []);

    return (
        <div id="wrapper">
          <div style={{ padding:30 }}>             
              {data !== undefined && data !== null ? (
                <div>
                    <NavbarValidador 
                            colorDividerNavbar1={data.colorDividerNavbar1}
                            colorDividerNavbar2={data.colorDividerNavbar2}
                            colorDividerNavbar3={data.colorDividerNavbar3}
                            colorDividerNavbar4={data.colorDividerNavbar4}
                            logo={data.urlLogo}
                        />   
                    <Row 
                            style={{ 
                                paddingTop: '48px',
                                fontFamily: 'Georama, Arial, sans-serif',
                                fontSize: '24px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                color: (data.textColor) ? data.textColor : '#000',  
                                justifyContent: 'center',          
                            }} >
                            Consulta do Terminal
                        </Row>
                        <Row
                            style={{
                                paddingTop: '48px',
                                paddingBottom: '16px',
                                fontFamily: 'Georama, Arial, sans-serif',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                color: (data.textColor) ? data.textColor : '#000',
                            }} >
                            Informações Gerais
                        </Row>   
                        {data.bit_terminal_ativo ? (     
                            <>    
                                <Row style={infoStyle} > 
                                    Operador Lotérico: <p style={infoStyleData}> {data.nome_operador_ponto} </p>
                                </Row>    
                                <Row style={infoStyle} > 
                                    CNPJ: <p style={infoStyleData}> {data.cnpj_operador_ponto}</p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Ponto de Venda: <p style={infoStyleData}> {data.nome_ponto} ({(data.codigo_ponto_venda)})</p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Serial: <p style={infoStyleData}> {data.numero_serial}</p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Está Ativo? <p style={infoStyleData}>  {(data.bit_terminal_ativo)? "Sim" : "Não"}  </p>
                                </Row> 
                                <Row style={infoStyle} > 
                                    Tipo de terminal: <p style={infoStyleData}>  { (data.tipo_terminal)? 
                                                                                                                    ((data.tipo_terminal == 1) ? "Terminal do Operador" 
                                                                                                                    : (data.tipo_terminal == 2) ? "Terminal de Autoatendimento" : "") 
                                                                                                            : ""} </p>
                                </Row>  
                                <Row style={infoStyle} > 
                                    Endereço: <p style={infoStyleData}> {(data.logradouro && data.logradouro != "") ? ` ${data.logradouro ?? ""}, ` : "" }
                                                                                                    {(data.complemento && data.complemento != "") ? `${data.complemento ?? ""}, ` : "" }
                                                                                                    {(data.bairro && data.bairro != "") ? `${data.bairro ?? ""}, ` : "" }
                                                                                                    {`${data.sigla_uf ?? ""}. `}
                                                                                                    {cepFormatter(data.cep ?? "")}  </p>
                                </Row>
                                <div style={{ height: '200px'}}>                                    
                                </div>
                            </>       
                        ) : (
                            <div>  
                                <Row style={{ marginTop: 20, marginBottom: 20 }}> 
                                    <Col span={24}>
                                        <Alert  message="Terminal Inválido" description={
                                            <>
                                                <>Este terminal não foi encontrado em nossos registros. Portanto, os dados fornecidos por ele não são confiáveis.</>
                                                <Row><MailOutlined style={{ paddingRight: 5}} /> 
                                                    <a href={`mailto:${data.email ?? ""}`}>
                                                        { data.email ?? ""} 
                                                    </a>
                                                </Row>
                                                <Row><WhatsAppOutlined style={{paddingRight: 5 }} /> 
                                                    <a href={`https://api.whatsapp.com/send?phone=${data.numeroCelular?.replace(/\D+/g, '') ?? ""}`}>
                                                        {data.numeroCelular ?? ""}
                                                    </a>
                                                </Row>
                                            </>
                                        }   type="error" showIcon />
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <NavbarBottomValidador 
                                colorDividerNavbar1={data.colorDividerNavbar1}
                                colorDividerNavbar2={data.colorDividerNavbar2}
                                colorDividerNavbar3={data.colorDividerNavbar3}
                                colorDividerNavbar4={data.colorDividerNavbar4}
                                urlLogoEstado={data.urlLogoEstado}
                                nomeGestor={data.nomeGestor}
                                redes={data.redes}
                            />
                    </div>
                ) : (
                    <div>
                    </div>
                )}
          </div>
        </div>
      );
}

export default Terminal;