import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { Context } from './context/LoginContext';
import { PrivateRoute } from './routes/PrivateRoute';
import { adminRoutes } from './routes';
import Ticket from "./pages/Ticket"; 
import Terminal from "./pages/Terminal"; 
import Validador from "./pages/Validador";
import { MenuProvider } from "./context/MenuContext";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";


// Sentry.init({
//     dsn: "https://dfdf6fe3fca64764a814bc05dee36fde@o1336803.ingest.sentry.io/6636104",
//     integrations: [new BrowserTracing()],

//     tracesSampleRate: 0.01,
//   });

function App(props) {
    const { state } = useContext(Context);

    return (
        <Switch>            
            <Route exact path='/consultaticket/:tipo/:operador/:autorizacao' component={Ticket} /> 
            <Route exact path='/consultaterminal/:terminal_id' component={Terminal} /> 
            <Route exact path='/validador/:operador_id/:domain/:hash_val' component={Validador} />
            {/* <MenuProvider>
                {adminRoutes.map((prop, key) => {
                    return (
                        <PrivateRoute
                            key={key}
                            exact path={prop.path}
                            component={prop.component}
                            isAuthenticated={((state?.token ?? false) ? true : false) && (state?.isAuthenticated ?? false) }
                            />);
                })}
            </MenuProvider> */}
        </Switch>
    );
}

export default App;
